import { Iterable } from 'immutable/dist/immutable';

const transformImmutableToJS = (immutableProps) => {
    return Object.keys(immutableProps).reduce((acc, key) => {
        const immutableValue = immutableProps[key];
        const value = Iterable.isIterable(immutableValue) ? immutableValue.toJS() : immutableValue;
        return { ...acc, [key]: value };
    }, {});
};

export default transformImmutableToJS;
